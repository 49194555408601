










import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class Login extends Vue {
  isDark = false;
  created() {
    this.isDark = this.$vuetify.theme.dark;
    this.$vuetify.theme.dark = true;
  }

  destroyed() {
    this.$vuetify.theme.dark = this.isDark;
  }
}
